import { myAxios } from './axios'

export const signin = (email: string, password: string) => {
  return myAxios.post(`admin/signin`, { email, password }).then(({ data }) => data)
}

export const logout = (email: string, token: string) => {
  return myAxios.post(`auth/logout`, { token }).then(({ data }) => data)
}

export const signup = async (admin: any) =>
{
  const response = await myAxios.post(`admin/signup`, admin)
  console.log('response api ===', response);
  return response.data
}
