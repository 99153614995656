import { defineStore } from 'pinia'
import { getAdminData, removeAdminData, saveAdminData } from '../services/admin'
import { getAdminDetails, setAdminAddress, setAdminLocation, updateAdmin } from '../api/admin.api'
import { AdminType,LocationType } from '../types/admin.types'
import { AddressType } from '../types/address.types'
import { createAddress } from '../api/address.api'

export const useAdminStore = defineStore({
  id: 'adminStore',
  state: () => ({
    admin: getAdminData(),
  }),
  actions: {
    async doGetAdmin() {
      try {
        const response = await getAdminDetails();
        if (!response.error)
        {
          //console.log('error in getting store response ', response)
          const responseData = response.data
          saveAdminData(responseData)
          this.admin = responseData
          // console.log('auth data ---', this.authData)
          return responseData
        } else
        {
          throw new Error(response.message);
        }
      } catch (error) {
        //console.log(error)
        throw error
      }
    },
    async doRemoveAdmin() {
      try {
          removeAdminData()
          this.admin = null
          return true
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    async doUpdateAdmin(adminId:string,admin:AdminType)
    {
      try
      {
        const response = await updateAdmin(adminId,admin);
        if (!response.error)
        {
          //console.log('error in creating store response', response)
          const responseData = response.data
          saveAdminData(responseData)
          this.admin = responseData
          // console.log('auth data ---', this.authData)
          return responseData
        } else
        {
          throw new Error(response.message);
        }
      } catch (error)
      {
        console.log(error);
      }
    },
  async doSetAdminLocation(location:Partial<LocationType>)
    {
      try
      {
        const response = await setAdminLocation(this.admin._id,location);
        if (!response.error)
        {
          //console.log('error in creating store response', response)
          const responseData = response.data
          saveAdminData(responseData)
          this.admin = responseData
          console.log('auth data ---', response);
          return responseData
        } else
        {
          throw new Error(response.message);
        }
      } catch (error)
      {
        console.log(error);
      }
    },
    async doSetAdminAddress(address:AddressType)
    {
      try
      {
        const createdAddress = await createAddress(address);

        const response = await setAdminAddress(this.admin._id,createdAddress.data._id);
        if (!response.error)
        {
          console.log('address update response', response)
          const responseData = response.data
          saveAdminData({...responseData,address:address});
          this.admin = responseData
          console.log('address data ---', response);
          return {...responseData,address:address}
        } else
        {
          throw new Error(response.message);
        }
      } catch (error)
      {
        console.log(error);
      }
    }
  },
})
