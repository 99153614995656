import { AuthType } from '../pages/auth/types'

export const saveAuthData = (authData: AuthType) => {
  const data = JSON.stringify(authData)
  localStorage.setItem('authData', data)
}

export const getAuthData = () => {
  try {
    const authData = localStorage.getItem('authData')
    if (authData) {
      return JSON.parse(authData)
    } else {
      throw Error
    }
  } catch (error) {
    return null
  }
}

export const removeAuthData = () => {
  try {
    console.log('removing authData ...')
    localStorage.removeItem('authData')
    return null
  } catch (error) {
    return null
  }
}
