import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'
import '@fontsource/poppins' // Defaults to weight 400
import '@fontsource/poppins/400.css' // Specify weight
import '@fontsource/poppins/400-italic.css' // Specify weight and style
import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'
import { createPinia } from 'pinia'
import  CkeditorPlugin  from '@ckeditor/ckeditor5-vue';
//import Vue3GoogleMap from 'vue3-google-map';
//import { GoogleMap } from 'vue3-google-map';


const app = createApp(App)
const pinia = createPinia() // Create Pinia instance

app.use(pinia) // Use Pinia
.use( CkeditorPlugin )
app.use(stores)
app.use(router)
app.use(i18n)
// app.use(GoogleMap, {
//   load: {
//     key: 'YOUR_GOOGLE_MAPS_API_KEY',  // Replace with your Google Maps API key
//   },
// });
 app.use(createVuestic({ config: vuesticGlobalConfig }))
if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')
