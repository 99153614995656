import axios from 'axios'
import { AxiosError } from 'axios'
import { getAuthData } from '../services/auth'
import { useAuthStore } from '../stores/auth-store'
import { useToast } from 'vuestic-ui/web-components'
import { createGlobalConfig } from 'vuestic-ui/dist/types/services/global-config'
// import 'dotenv/config'

const { init } = useToast()
export const myAxios = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
})

myAxios.interceptors.request.use((config: any) => {
  const token = getAuthData()?.token
  // console.log('token------',token)
  if (token) config.headers = { ...config.headers, Authorization: `Bearer ${token}` }
  return config
})

myAxios.interceptors.response.use(
  (response) => {
    console.log('axios response')
    return response
  },

  (error: any) => {
    console.log('intercepting response ')
    init({
      message: error?.response?.data?.message || error.message,
      color:"danger"
    })
    console.log('axios error request --->', error.request.responseURL)
    if (error.response?.status == 401 && !error.request.responseURL.includes('logout')) {
      useAuthStore().doLogout()
    }
    return Promise.reject(error)
  },
)

// httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
//   throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
// });
