import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '../stores/auth-store'
import { useMedicalStore } from '../stores/medical-store'
//import { storeToRefs } from 'pinia';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'orders' },
  },
  {
    name: 'dashboard',
    path: '/',
    component: () => import('../layouts/AppLayout.vue'),
    redirect: { name: 'orders' },
    children: [
      {
        name: 'orders',
        path: 'orders',
        component: () => import('../pages/bookings/BookingPage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'profile',
        path: 'profile',
        component: () => import('../pages/admin/preferences/Preferences.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'posts',
        path: 'posts',
        component: () => import('../pages/posts/PostsPage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'post',
        path: 'post/:postId?',
        component: () => import('../pages/posts/single-posts/SinglePostPage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('../layouts/AuthLayout.vue'),
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    path: '/store',
    component: () => import('../layouts/AppLayout.vue'),
    children: [
      {
        name: 'storeProfile',
        path: 'profile',
        component: () => import('../pages/profile/medical-store/create-medical-store/CreateMedicalStore.vue'),
        meta: {
          requiresAuth:true,
        }
      },
      {
        name: 'createStore',
        path: 'create',
        component: () => import('../pages/profile/medical-store/create-medical-store/CreateMedicalStore.vue'),
        meta: {
          requiresAuth:true,
        }

      },
      {
        path: '',
        redirect: { name: 'storeProfile' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  console.log('before .....')
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!useAuthStore().authData) {
      next({
        path: '/auth/login',
        query: {
          previousPath: from.fullPath,
        },
      })
    } else
    {
      next()
    }
  }
  else if (to.fullPath === '/auth/login') {
    // Setting previous path here so that it can be rerouted to old url that was open before login
    next({
      path: '/auth/login',
      query: {
        previousPath: from.fullPath,
      },
    })
  } else {
    next()
  }
})
export default router