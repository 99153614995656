import { defineStore } from 'pinia'
import { getAuthData, saveAuthData, removeAuthData } from '../services/auth'
import { signin, logout } from '../api/auth'
import { useMedicalStore } from './medical-store'
import { useAdminStore } from './admin-store'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    authData: getAuthData(),
  }),
  actions: {
    async doLogin(email: string, password: string) {
      try {
        const response = await signin(email, password)
        if (!response.error)
        {
          console.log('login res store ', response)
          const responseData = response.data
          saveAuthData(responseData)
          this.authData = responseData

          // get and save admin details
          useAdminStore().doGetAdmin();

          // console.log('auth data ---', this.authData)
          return responseData
        } else
        {
          throw new Error(response.message);
        }
      } catch (error) {
        //console.log(error)
        throw error
      }
    },
    async doLogout() {
      try {
        console.log('calling do logout ...')
        const { email, token } = this.authData
        if (email && token) {
          //await logout(email, token)
          removeAuthData();
          useAdminStore().doRemoveAdmin();
          this.authData = null
          return true
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    },
  },
})
