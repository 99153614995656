// export default {
//   presets: {
//     light: {
//       primary: '#000000',
//       secondary: '#000000',

//       // backgroundPrimary: '#F4F6F8',
//       // backgroundSecondary: '#FFFFFF',
//       // backgroundCardPrimary: '#F7F9F9',
//       // backgroundCardSecondary: '#ECFDE6',
//       // success: '#228200',
//       // info: '#158DE3',
//       // danger: '#E42222',
//       // warning: '#FFD43A',
//       backgroundPrimary: 'rgba(61,61,61,0.2)',
//       backgroundSecondary: '#FFFFFF',
//       backgroundDark: '#000000',
//       backgroundCardPrimary: '#00FF00',
//       backgroundCardSecondary: '#FF0000',
//       success: '#228200',
//       info: '#00FF00',
//       danger: '#CE111B',
//       warning: '#FFD43A',
//       gray: 'rgba(61,61,61,0.2)',
//       dark: 'rgba(61,61,6,0.8)',
//     },
//     dark: {
//       backgroundCardPrimary: '#111827',
//       backgroundCardSecondary: '#0f172a',
//     },
//   },
// }

export default {
  presets: {
    light: {
      backgroundPrimary: '#F4F6F8',
      backgroundSecondary: '#FFFFFF',
      backgroundCardPrimary: '#F7F9F9',
      backgroundCardSecondary: '#ECFDE6',
      success: '#228200',
      info: '#158DE3',
      danger: '#E42222',
      warning: '#FFD43A',
    },
    dark: {
      backgroundCardPrimary: '#111827',
      backgroundCardSecondary: '#0f172a',
    },
  },
}
