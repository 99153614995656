import { AdminType } from '../types/admin.types'

export const saveAdminData = (adminData: AdminType) => {
  const data = JSON.stringify(adminData)
  localStorage.setItem('admin', data)
}

export const getAdminData = () => {
  try {
    const medicalData = localStorage.getItem('admin')
    if (medicalData) {
      return JSON.parse(medicalData)
    } else {
      throw Error
    }
  } catch (error) {
    return null
  }
}

export const removeAdminData = () => {
  try {
    console.log('removing authData ...')
    localStorage.removeItem('admin')
    return null
  } catch (error) {
    return null
  }
}
