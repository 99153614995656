import { createGlobalConfig } from 'vuestic-ui/dist/types/services/global-config'
import { AdminType,LocationType } from '../types/admin.types'
import { myAxios } from './axios'

export const updateAdmin = (adminId:string,admin:AdminType) => {
    return myAxios.put(`admin/${adminId}`, admin).then(({ data }) => data)
}

export const setAdminLocation = (adminId:string,location:Partial<LocationType>) => {
    return myAxios.put(`admin/location/${adminId}`, location).then(({ data }) => data);
}

export const setAdminAddress = (id:string,addressId:string) => {
    console.log(`going add id --- ${id} , add id = ${addressId}`)
    return myAxios.put(`admin/address`, {id,addressId}).then(({ data }) => data);
}
  
export const getAdminDetails = () => {
    return myAxios.get(`admin/me`).then(({ data }) => data)
  }